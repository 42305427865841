/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';  
import { useNavigate, useLocation } from 'react-router-dom';  
import { menuItems } from "./menuItems"
import { isAuthenticated, userDetails } from '../../utils';
import { DistributorMenu } from './DistributorMenu';
import { UserMenu } from './UserMenu';
const Navigation = ({ isOpen, currentRoute }) => {  
    const navigate = useNavigate();  
    const location = useLocation();  
    const [userDate, setUserDate] = useState();
    
    useEffect(() => {
      setUserDate(userDetails());
    }, [isAuthenticated()]);
    

    const LogOut = () => {
        sessionStorage.removeItem('token', { path: '/' });
        sessionStorage.removeItem('agentDetails', { path: '/' });
        sessionStorage.removeItem('userDetails', { path: '/' });
        sessionStorage.removeItem('agentData', { path: '/' });
        sessionStorage.removeItem('userdata', { path: '/' });
        localStorage.removeItem('agentData');
        localStorage.removeItem('userdata');
        navigate('/');
    };
    
    // Select the appropriate menu based on user role
    const selectedMenuItems = ["Super Distributor","Manager"].includes(userDate?.role) ? menuItems : userDate?.role === 'Distributor' ? DistributorMenu : userDate?.role === 'User' ? UserMenu :[];

    return (  
        <nav>  
            <ul className={`toresponsive ${isOpen ? 'show' : ''}`} id="">  
                {selectedMenuItems.map((item, index) => (  
                    <li key={index}>  
                        <a  
                            className={currentRoute === item.title || item.includes.includes(location.pathname) ? "active main_a a_pointer" : "main_a a_pointer"}  
                            // onClick={() => navigate(item.path || '/')}  
                            // onClick={() => navigatePage(item.path || '/')}  
                            href={(item.path || '/')}
                        >  
                            {item.title}  
                        </a>  
                        {!item.isSingle && (  
                            <ul>  
                                {item.subItems.map((subItem, subIndex) => (  
                                    <li key={subIndex}>  
                                        {/* <a className={`a_pointer ${location.pathname === subItem.path && 'child-active'}`} onClick={() => navigate(subItem.path)}>   */}
                                        <a className={`a_pointer ${location.pathname === subItem.path && 'child-active'}`}href={subItem.path}>  
                                            {subItem.title}  
                                        </a>  
                                    </li>  
                                ))}  
                            </ul>  
                        )}  
                    </li>  
                ))}  
                <li>  
                    <a className="main_a a_pointer logOut_bottom_btn" onClick={() => LogOut()}>LogOut</a>  
                </li>  
            </ul>  
        </nav>  
    );  
};  

export default Navigation;